<template>
  <div class="action-block">
    <div class="block-item">
      <div class="block-content">
        <div class="start-action" :class="{ 'trigger-action': automation.start_trigger }" @click="showTriggerModal">
          <template v-if="automation.start_trigger && triggerById(automation.start_trigger)">
            <component :is="triggerById(automation.start_trigger).component" :automation="automation"
              :trigger="triggerById(automation.start_trigger)"></component>
          </template>
          <template v-else>
            Add a start trigger
          </template>
          <div class="block-controls" style="right: 0px" @click="deleteStartTrigger">
            <span class="btn-action" title="Delete">
              <i class="uil uil-trash-alt"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="isShowTriggerModal" title="Add a Start Trigger">
      <div class="list-trigger">
        <div v-for="(trigger, index) in triggers" :key="`trigger-${index}`" class="trigger-item"
          :class="{ active: selectedTrigger && selectedTrigger.id === trigger.id }" @click="selectTrigger(trigger)">
          <div class="icon">
            <i :class="trigger.icon"></i>
          </div>
          <div class="label">
            {{ trigger.label }}
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowTriggerModal = false">Cancel</b-button>
        <b-button variant="primary" :disabled="!selectedTrigger" @click="confirmSeletedTrigger">Select</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import RedeemsCoupon from './Triggers/RedeemsCoupon'
import Birthday from './Triggers/Birthday'
import Anniversary from './Triggers/Anniversary'
import Date from './Triggers/Date'
import Keywords from './Triggers/Keywords'
import Samcart from './Triggers/Samcart'
import GotoWebinar from './Triggers/GotoWebinar'
import CustomDateField from './Triggers/CustomDateField'
import Webhook from './Triggers/Webhook'
import Stripe from './Triggers/Stripe'
import EmailReply from './Triggers/EmailReply'

export default {
  components: {
    RedeemsCoupon, Birthday, Date, Keywords, Samcart, Anniversary,
    GotoWebinar, CustomDateField, Webhook, Stripe, EmailReply,
  },

  props: {
    automation: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowTriggerModal: false,
      triggers: [
        { component: 'RedeemsCoupon', label: 'Redeem Offer', icon: 'uil uil-bill', id: 'redeem-offer' },
        { component: 'Birthday', label: 'Birthday', icon: 'uil uil-calendar-alt', id: 'birthday' },
        { component: 'Anniversary', label: 'Anniversary', icon: 'uil uil-crosshair', id: 'anniversary' },
        { component: 'Date', label: 'Date', icon: 'uil uil-clock', id: 'date' },
        { component: 'Keywords', label: 'Keywords', icon: 'uil uil-tag', id: 'keywords' },
        { component: 'GotoWebinar', label: 'GotoWebinar', icon: 'uil uil-users-alt', id: 'gotowebinar' },
        { component: 'Webhook', label: 'Webhook', icon: 'uil uil-processor', id: 'webhook' },
        { component: 'Stripe', label: 'Stripe', icon: 'uil uil-dollar-sign', id: 'stripe' },
      ],
      selectedTrigger: null,
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    hasSendgridIntegration() {
      return this.user && this.user.business && this.user.business.has_integrations.sendgrid
    },
    customFields() {
      return this.$store.getters["auth/customFields"];
    },
  },

  mounted() {
    if (this.user && this.user.business.use_integration) {
      this.triggers.push({
        component: 'Samcart', label: 'Samcart', icon: 'uil uil-shopping-cart-alt', id: 'samcart'
      })
    }

    if (this.hasSendgridIntegration) {
      this.triggers.push({
        component: 'EmailReply', label: 'Email Reply', icon: 'uil uil-mailbox', id: 'email-reply'
      })
    }

    if (process.env.NODE_ENV === 'production' && ![4776,3379].includes(this.user.id)) {
      this.triggers = this.triggers.filter(item => item.id !== 'stripe')
    }

    if (this.customFields && this.customFields.length) {
      this.customFields.forEach(item => {
        if (item.is_active && item.type === 2) { // date type only
          this.triggers.push({
            component: 'CustomDateField', label: item.name, icon: 'uil uil-stopwatch', id: item.id.toString()
          })
        }
      });
    }
  },

  methods: {
    triggerById(id) {
      return this.triggers.find(item => item.id === id)
    },

    showTriggerModal() {
      if (this.automation.start_trigger) {
        this.$emit('showModal', true)
      } else {
        this.isShowTriggerModal = !this.isShowTriggerModal
      }

    },

    selectTrigger(trigger) {
      this.selectedTrigger = trigger
    },

    confirmSeletedTrigger() {
      this.automation.start_trigger = this.selectedTrigger.id
      this.automation.options = {}
      this.isShowTriggerModal = false
      if (['webhook'].includes(this.automation.start_trigger) && !this.automation.webhook)
      {
        this.$store.dispatch('automation/generateWebhookUrl').then((res) => {
          const { fullUrl, uniqueId } = res
          this.automation.webhook = uniqueId
          this.automation.options.webhook = fullUrl
          this.$nextTick(() => {
            this.$emit('showModal', true)
          })
        })
      } else {
        this.automation.webhook = null
        this.automation.options.webhook = null
        this.$nextTick(() => {
          this.$emit('showModal', true)
        })
      }
    },

    deleteStartTrigger() {
      this.selectedTrigger = null
      this.automation.start_trigger = null
      this.automation.options = {}
    }
  },

}
</script>

<style lang="scss" scoped>
.start-action {
  color: #1f2129;
  user-select: none;
  cursor: pointer;
  border: 2px dashed #9298ad;
  padding: 16px 12px;
  min-width: 145px;
  display: inline-block;
  font-weight: 700;

  &.trigger-action {
    background-color: #31a952;
    color: #fff;
    border: 1px solid #2a9c4a;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.13);
    border-radius: 5px;
  }

  .block-controls {
    color: #4b4b5a;
  }
}

.list-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row;
  flex-wrap: wrap;

  .trigger-item {
    display: inline-block;
    text-align: center;
    margin: 5px;
    cursor: pointer;
    width: 105px;

    .icon {
      width: 96px;
      height: 96px;
      border: 2px solid #ced3e0;
      border-radius: 10px;
      margin-bottom: 9px;
      position: relative;
      text-align: center;
      line-height: 92px;
      color: #ced3e0;

      .uil {
        font-size: 46px;
      }
    }

    .label {
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      color: #414141;
    }

    &:hover,
    &.active {
      .icon {
        border-color: #497ab8;
        color: #497ab8;
      }
    }
  }
}</style>